
.Menu-button {
    width: 200px;
    height: 40px;
    color: #000000;
}

.Medium-menu-button {
    width: 200px;
    height: 80px;
    color: #000000;
}

.Big-menu-button {
    width: 200px;
    height: 160px;
    color: #000000;
}
