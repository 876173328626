.text1 {
    margin: 20px;
    /*justify-content: space-around;*/
    /*text-align: justify;*/
}

.has-text-align-right {
    margin-left: 40px;
    text-align: right;
}

p {
    text-indent: 40px;
}

A:link.alink {
    text-decoration: none;
}

A:visited.alink {
    text-decoration: none;
}

A:active.alink {
    text-decoration: none;
}

A:hover.alink {
    text-decoration: underline; /* Ссылка подчеркнутая и надчеркнутая */
    color: cornflowerblue; /* Цвет ссылки */
}

